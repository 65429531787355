/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { ToastContainer } from "react-toastify";
import CustomTheme from "./CustomTheme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { initTests } from "../../actions/actions";
import { IDocumentState } from "../../interfaces";
import MainContent from "../MainContent/MainContent";
import html2pdf from "html2pdf.js";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { delay } from "lodash";

import Header from "../Header/Header";
import { ITestsResult } from "twillio-tests/core/TestResults";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import Footer from "./Footers/Footer";
interface IAppProps {
  document: IDocumentState;
  initTests(): void;
  config: TestConfiguration;
  testResult?: ITestsResult;
}

function mapStateToProps(state: any) {
  return {
    document: state.document,
    config: state.tests.config,
    testResult: state.tests.testResult,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    initTests() {
      dispatch(initTests());
    },
  };
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

const App = (props: IAppProps) => {
  const {
    document: { layout, isLoading, theme, uuid },
    initTests,
    config,
    testResult,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const configTheme = (() => {
    const darkModeOption = props.config?.options?.darkMode;
    if (darkModeOption === "true") return true;
    if (darkModeOption === "false") return false;
    return !!props.config?.useDarkMode;
  })();

  const [darkMode, setDarkMode] = useState<boolean>(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode !== null ? JSON.parse(savedMode) : configTheme;
  });

  useEffect(() => {
    if (props.config?.useDarkMode !== undefined || props.config?.options?.darkMode !== undefined) {
      localStorage.setItem("darkMode", JSON.stringify(configTheme));
    }
  }, [configTheme]);

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    localStorage.removeItem("darkMode");
    setDarkMode(configTheme);
  }, [props.config?.theme, props.config?.options?.darkMode]);

  React.useEffect(() => {
    initTests();
  }, []);

  React.useEffect(() => {
    const options = props.config?.options;
    if (options && options["report"] === "pdf") {
      delay(() => exportPDF(), 200);
    }
  }, [props.config]);

  React.useEffect(() => {
    if (!isLoading) {
      document.getElementById("root-spinner")?.remove();
    }
  }, [isLoading]);

  const exportPDF = async () => {
    // setPdfDownloading(true);
    const element = document.getElementsByClassName("pdfReport")[0];
    const opt = {
      pagebreak: { avoid: "img" },
      margin: theme === "livevox" ? [14, 0, 20, 0] : [10, 10, 10, 10],
      filename: `network-test-highlights-${uuid}.pdf`,
      html2canvas: { windowWidth: 959 },
      jsPDF: { orientation: "p", unit: "pt", format: "letter" },
    };
    // New Promise-based usage:
    await html2pdf().from(element).set(opt).save();
    // setPdfDownloading(false);
  };

  const useNewDesign = !!props.config?.useNewWidgetDesign;

  const handleDownload = async () => {
    if (ref.current === null) {
      return;
    }

    const elementsToHide: HTMLElement[] = Array.from(ref.current.querySelectorAll(".hide-for-image")).map((el) => el as HTMLElement);

    elementsToHide.forEach((el) => {
      el.style.display = "none";
    });

    try {
      const pngDataUrl = await toPng(ref.current);

      const originalWidth = ref.current.clientWidth;
      const originalHeight = ref.current.clientHeight;
      const desiredWidth = 1230;
      const aspectRatio = originalHeight / originalWidth;
      const desiredHeight = desiredWidth * aspectRatio;

      const pdf = new jsPDF({
        orientation: desiredWidth > desiredHeight ? "landscape" : "portrait",
        unit: "px",
        format: [desiredWidth, desiredHeight],
      });

      pdf.addImage(pngDataUrl, "PNG", 0, 0, desiredWidth, desiredHeight);
      pdf.save(`network-test-highlights-${uuid}.pdf`);
    } catch (error) {
      console.error("Couldn't generate the PDF: ", error);
    }

    elementsToHide.forEach((el) => {
      el.style.display = "";
    });
  };
  const handleDarkModeToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <MuiThemeProvider theme={CustomTheme(theme, useNewDesign, darkMode)}>
      <CssBaseline />
      <ToastContainer />
      {useNewDesign ? (
        <div className={`new-wrapper pdfReport report-${layout.report}`} ref={ref}>
          <>
            <div className="header-wrapper hidden" dangerouslySetInnerHTML={{ __html: layout.header }} />
            <Header config={config} testResult={testResult} setDarkMode={handleDarkModeToggle} />
          </>
          <MainContent downloadPdf={() => handleDownload()} />
          {config?.theme === "nice" ? (
            <div data-html2canvas-ignore className="footer-wrapper new-nice-footer" dangerouslySetInnerHTML={{ __html: layout.footer }} />
          ) : (
            testResult !== null && <Footer config={config} darkMode={darkMode} />
          )}
        </div>
      ) : (
        <div className={`pdfReport wrapper report-${layout.report}`} style={wrapperStyle(layout.backgroundColor)}>
          <div className="header-wrapper" dangerouslySetInnerHTML={{ __html: layout.header }} />
          <MainContent />
          <div data-html2canvas-ignore className="footer-wrapper" dangerouslySetInnerHTML={{ __html: layout.footer }} />
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
